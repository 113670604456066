<template>
  <div class="page">
    <div class="search-main">
      <div class="icon-main">
        <svg-icon icon-class="icon_refill_card" />
      </div>
      <h2 class="search-title">充值查询</h2>
      <div class="search-input-wrap">
        <div class="input-wrap">
          <input type="text" placeholder="选择已绑流量卡或输入充值卡号" v-model.trim="cardNum" @click="cardLists.length > 0 && selectCard()" :readonly="cardLists.length > 0 ? true : false">
        </div>
        <div class="scan-code"><svg-icon icon-class="icon_scan" /></div>
      </div>
      <div class="btn-wrap">
        <button class="btn" @click="submit()">查询</button>
      </div>
    </div>
    <div v-if="cardLists.length > 0">
      <van-popup v-model="isShow" position="bottom">
        <van-picker title="请选择绑定的流量卡" show-toolbar :columns="cardLists" @change="onChange" @confirm="onConfirm"
  @cancel="onCancel" ></van-picker>
      </van-popup>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { Notify, Picker, Popup } from 'vant'
import { mapState, mapMutations } from 'vuex'

Vue.use(Notify)
Vue.use(Picker)
Vue.use(Popup)
export default {
  name: 'search',
  components: {
  },
  data () {
    return {
      headerTitle: '充值查询',
      errMsg: '',
      cardNum: '',
      isShow: false
    }
  },
  created () {
  },
  mounted () {
    document.title = this.$route.meta.title
  },
  methods: {
    ...mapMutations({
      setSearch: 'search/setSearch'
    }),
    // 选择流量卡
    selectCard () {
      this.isShow = true
    },
    // 查询
    submit () {
      if (this.cardNum === '') {
        Notify({
          message: '请输入流量卡卡号',
          color: '#200202',
          background: 'rgba(255, 139, 32, 0.4)',
          className: 'popup-top'
        })
        return
      }
      this.setSearch({ cardNum: this.cardNum })
      console.log('success')
    },
    onChange () {

    },
    onConfirm (e) {
      this.cardNum = e
      this.isShow = false
    },
    onCancel () {
      this.isShow = false
    }

  },
  computed: {
    ...mapState({
      cardLists: state => state.search.cardLists
    })
  }
}
</script>

<style lang="scss" scoped>
  .search-main{
    padding: 4rem 0;
    position: relative;
    .svg-icon{
      width: 23.4375rem;
      height: 15.625rem;
      margin: 0 auto;
      display: block;
    }
  }

  .search-title {
    font-size: 1.25rem;
    line-height: 1.625rem;
    color: #019bfe;
    margin-top:0.375rem;
    text-align: center;
    font-weight: normal;
  }
  .search-input-wrap {
    margin-top: 1.125rem;
    height: 2.75rem;
    background-color: #fff;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    border-top:1px solid #e5e5e5;
    border-bottom: 1px solid #f2f2f2;
    padding-right: 0.7rem;
    .input-wrap {
      flex: 1;
      overflow: hidden;
      padding-left: 1.25rem;
      height: 100%;
      input {
        width: 100%;
        height: 100%;
        font-size: 0.875rem;
      }
    }
    .scan-code {
      padding: 0 1.25rem;
      height: 2.75rem;
      line-height: 2.75rem;
      color: #333;
      position: relative;
      .svg-icon{
        width: 1.5rem;
        height: 1.5rem;
        position: absolute;
        left:50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
  .btn-wrap{
      margin-top: 1.75rem;
      padding: 0 1.25rem;
    }
   .err-info{
     height:2.1875rem;
     line-height: 2.1875rem;
     width: 100%;
     font-size: 0.875rem;
     padding: 0 1.25rem;
     box-sizing: border-box;
     position: absolute;
     left: 0;
     top:0;
     background-color:  rgba(255, 139, 32, 0.4);
     color:#200202;
   }
</style>
